import { Head, Link, useAuth } from "zudoku/components";
import { QueryClientProvider } from "@tanstack/react-query";
import { Progress } from "./Progress";
import { cn } from "../components/cn";
import { ApiKeyGroup } from "./ApiKeyGroup";
import { useSignedMutation, useSignedQuery } from "../useQuery";
import { Skeleton } from "zudoku/ui/Skeleton";
import { AddAppDialog } from "./AddAppDialog";
import {
  type Subscription,
  SUBSCRIPTIONS_PATH,
} from "../queries/subscriptionsQuery";
import { queryClient } from "../queries/client";
import { Button } from "zudoku/ui/Button";
import { EditIcon } from "lucide-react";
import { Badge } from "zudoku/ui/Badge";
import { UpdateBillingInformation } from "./UpdateBillingInformation";
import { Recurly } from "../Recurly";
import { ActionButton } from "zudoku/ui/ActionButton";
import { useEffect, useRef, Suspense } from "react";

const SubscriptionSkeleton = () => {
  return (
    <div className="self-start border rounded shadow w-full">
      <div className="bg-border/20 p-6 border-b flex justify-between items-center">
        <div className="flex flex-col gap-1.5 w-72">
          <Skeleton className="h-4 w-full rounded-full bg-gray-300" />
          <Skeleton className="h-4 w-8/12 rounded-full bg-gray-300" />
        </div>

        <Skeleton className="h-10 w-32 bg-gray-300" />
      </div>
      <div className="border-b p-6 flex flex-col gap-1.5 ">
        <Skeleton className="h-4 w-28 rounded-full bg-gray-300" />
        <Skeleton className="h-4 w-full rounded-full bg-gray-300" />
      </div>
      <div className="border-b p-6 gap-4 grid-cols-2 grid">
        <div>
          <Skeleton className="h-4 w-24 rounded-full bg-gray-300" />
        </div>
        <div className="flex justify-end">
          <Skeleton className="h-10 w-32 self-end bg-gray-300" />
        </div>
        <div>
          <Skeleton className="h-4 w-80 rounded-full bg-gray-300" />
        </div>
        <div className="flex gap-2 justify-end w-full">
          <Skeleton className="h-10 w-10 self-end bg-gray-300" />
          <Skeleton className="h-10 w-10 self-end bg-gray-300" />
        </div>
      </div>
    </div>
  );
};

const MySubscriptionsSkeleton = () => {
  return (
    <section className="flex flex-col py-12 gap-5 lg:gap-10 max-w-screen-xl mx-auto not-prose">
      <Head>
        <title>Subscriptions & Keys</title>
      </Head>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-[1fr_max-content]">
        <h2 className="font-semibold text-3xl">My Subscriptions</h2>
      </div>
      <p>
        <Skeleton className="h-4 w-8/12 rounded-full bg-gray-300" />
      </p>
      <SubscriptionSkeleton />
    </section>
  );
};

const SubscriptionCard = ({ subscription }: { subscription: Subscription }) => {
  const rollApiKeyMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscription.id}/apps/{appId}/keys/`,
    { method: "POST" },
  );

  const deleteApiKeyMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscription.id}/apps/{appId}/keys/{keyId}`,
    { method: "DELETE" },
  );

  const deleteAppMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscription.id}/apps/{appId}`,
    { method: "DELETE" },
  );

  return (
    <div className="self-start border rounded shadow w-full">
      <div className="bg-border/20 p-6 border-b grid md:grid-cols-2 gap-4 items-center">
        <div>
          <div id="minutecast" className="font-semibold text-lg">
            {subscription.label}
          </div>
          <p className="text-muted-foreground text-sm">
            Showing consumption for November
          </p>
        </div>
        <div className="flex gap-2 md:justify-end">
          {subscription.status !== "active" ? (
            <Badge>Canceled</Badge>
          ) : (
            <>
              {subscription.manageable !== false &&
                subscription.planId !== "free/free/v1" && (
                  <Button variant="outline" className="flex gap-2" asChild>
                    <Link to={`/subscriptions/${subscription.id}`}>
                      <EditIcon size={18} />
                      Manage
                    </Link>
                  </Button>
                )}{" "}
              <AddAppDialog subscriptionId={subscription.id} />
            </>
          )}
        </div>
      </div>
      <div className="border-b p-6 flex flex-col gap-1.5 not-prose">
        <div className="flex justify-between">
          <span className="font-semibold">Calls</span>
          <span className="text-muted-foreground">
            <span
              className={cn(
                subscription.usage.used / subscription.usage.budget > 1 &&
                  "text-red-600",
              )}
            >
              {new Intl.NumberFormat().format(subscription.usage.used)}
            </span>{" "}
            of {new Intl.NumberFormat().format(subscription.usage.budget)}
          </span>
        </div>
        <Progress
          value={(subscription.usage.used / subscription.usage.budget) * 100}
          size="lg"
          variant={
            (subscription.usage.used / subscription.usage.budget) * 100 > 100
              ? "warning"
              : "default"
          }
        />
        <p className="text-muted-foreground text-sm">
          You will be charged for usage beyond your limits.{" "}
          <Link to="/pricing" className="hover:underline text-primary">
            View pricing.
          </Link>
        </p>
      </div>
      {subscription.status === "active" && (
        <div className="divide-y divide-border">
          {subscription.apps.map((app) => (
            <div className="p-6" key={app.id}>
              <ApiKeyGroup
                title={app.label}
                service={{
                  deleteKey: (id) =>
                    deleteApiKeyMutation.mutateAsync({
                      params: { keyId: id, appId: app.id },
                    }),
                  deleteGroup: (id) =>
                    deleteAppMutation.mutateAsync({
                      params: { appId: app.id },
                    }),
                  rollKey: (id) => {
                    return rollApiKeyMutation.mutateAsync({
                      params: { keyId: id, appId: app.id },
                    });
                  },
                }}
                id={app.id}
                keys={app.keys}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Page = () => {
  const subscriptionsQuery = useSignedQuery<Subscription[]>(SUBSCRIPTIONS_PATH);
  const developerQuery = useSignedQuery(`/v1/developer`, { staleTime: 0 });
  const emailVerificationMutation = useSignedMutation(
    `/v1/developer/email-verification`,
  );
  const freeSubscriptionMutation = useSignedMutation(
    `/v1/developer/subscriptions/free`,
    { method: "POST" },
  );

  const auth = useAuth();
  const hasMutatedRef = useRef(false);

  useEffect(() => {
    if (!hasMutatedRef.current) {
      freeSubscriptionMutation.mutate({
        data: {
          email: auth.profile.email,
          name: auth.profile.name,
        },
      });
      hasMutatedRef.current = true;
    }
  }, []);

  if (
    !developerQuery.data.emailVerified ||
    developerQuery.data.emailVerified === false
  ) {
    return (
      <section className=" max-w-screen-xl mx-auto py-24">
        <div className="grid place-items-center h-full">
          <div className="flex flex-col gap-2">
            <h2 className="text-center">Verify your email address</h2>
            <p>
              You need to verify your email address before you can manage your
              subscriptions.{" "}
            </p>
            <ActionButton
              isPending={emailVerificationMutation.isPending}
              variant="secondary"
              onClick={() => emailVerificationMutation.mutate({})}
            >
              Resend verification email
            </ActionButton>
            <ActionButton
              isPending={developerQuery.isRefetching}
              variant="ghost"
              onClick={() => developerQuery.refetch()}
            >
              I have verified my email
            </ActionButton>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="flex flex-col py-12 gap-5 lg:gap-10 max-w-screen-xl mx-auto not-prose">
      <Head>
        <title>Subscriptions & Keys</title>
      </Head>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-[1fr_max-content]">
        <h2 className="font-semibold text-3xl">My Subscriptions</h2>
        {subscriptionsQuery.data.length >= 2 ? (
          <UpdateBillingInformation />
        ) : null}
      </div>

      <p>
        Here are your subscriptions and API keys. When you're ready, head over
        to the{" "}
        <Link
          to="/documentation/getting-started"
          className="underline font-semibold"
        >
          Getting Started guide
        </Link>
        .
      </p>

      {!subscriptionsQuery.isFetchedAfterMount ? (
        <SubscriptionSkeleton />
      ) : (
        <>
          {subscriptionsQuery.data.length > 0 ? (
            subscriptionsQuery.data.map((subscription) => (
              <SubscriptionCard
                key={subscription.id}
                subscription={subscription}
              />
            ))
          ) : (
            <SubscriptionSkeleton />
          )}
          {subscriptionsQuery.isRefetching && <SubscriptionSkeleton />}
        </>
      )}
    </section>
  );
};

export const SubscriptionsPage = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Recurly>
        <Suspense fallback={<MySubscriptionsSkeleton />}>
          <Page />
        </Suspense>
      </Recurly>
    </QueryClientProvider>
  );
};
