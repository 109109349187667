import { Head } from "zudoku/components";
import { QueryClientProvider } from "@tanstack/react-query";
import { useSignedMutation, useSignedQuery } from "../useQuery";
import { queryClient } from "../queries/client";
import React from "react";
import { useForm } from "react-hook-form";
import { Card, CardContent, CardHeader, CardTitle } from "zudoku/ui/Card";
import { Label } from "zudoku/ui/Label";
import { Input } from "zudoku/ui/Input";
import { ActionButton } from "zudoku/ui/ActionButton";

const Page = () => {
  return (
    <section className="flex flex-col py-12 gap-5 lg:gap-10 max-w-screen-xl mx-auto not-prose">
      <Head>
        <title>Profile</title>
      </Head>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-[1fr_max-content]">
        <ProfileForm />
      </div>
    </section>
  );
};

type ProfileFormData = {
  name: string;
  businessName: string;
  phoneNumber: string;
};

export const ProfileForm = () => {
  const developerQuery = useSignedQuery(`/v1/developer`, { staleTime: 0 });
  const updateDeveloperMutation = useSignedMutation(`/v1/developer`, {
    method: "PATCH",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileFormData>({
    defaultValues: {
      name: developerQuery.data.name,
      businessName: developerQuery.data.businessName,
      phoneNumber: developerQuery.data.phoneNumber,
    },
  });

  return (
    <div className="max-w-xl mx-auto w-full">
      <form
        onSubmit={handleSubmit((data) =>
          updateDeveloperMutation.mutate({ data }),
        )}
        className="space-y-4"
      >
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold">Profile</h2>
          <ActionButton
            type="submit"
            isPending={updateDeveloperMutation.isPending}
          >
            Update
          </ActionButton>
        </div>
        <Card className="mt-5 overflow-hidden">
          <CardHeader className="border-b bg-muted">
            <CardTitle>Account Information</CardTitle>
          </CardHeader>
          <CardContent className="mt-4">
            <div>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                {...register("name", {
                  required: "First name is required",
                })}
                placeholder="First Name"
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name.message}</p>
              )}
            </div>
            <div>
              <Label htmlFor="businessName">Business Name</Label>
              <Input
                id="businessName"
                {...register("businessName")}
                placeholder="Business Name"
              />
            </div>
            <div>
              <Label htmlFor="phoneNumber">Phone Number</Label>
              <div className="flex items-center gap-2">
                <Input
                  id="phoneNumber"
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                  })}
                  placeholder="Phone Number"
                />
              </div>
              {errors.phoneNumber && (
                <p className="text-red-500 text-sm">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

export const ProfilePage = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Page />
    </QueryClientProvider>
  );
};
