import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "zudoku/ui/Dialog";
import { CreditCardIcon } from "lucide-react";
import { Button } from "zudoku/ui/Button";
import { useState } from "react";
import { HorizontalLine } from "../HorizontalLine";
import { useSignedMutation, useSignedQuery } from "../useQuery";
import { PaymentInformation } from "../checkout-page/CheckoutPage";
import { Callout } from "zudoku/components";
import { CreditCardForm } from "../CreditCardForm";
import { ActionButton } from "zudoku/ui/ActionButton";
import { Label } from "zudoku/ui/Label";
import { Input } from "zudoku/ui/Input";

export type CreditCardDefaultValues = {
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  country?: string;
};

export const UpdateBillingInformation = () => {
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const paymentInformation = useSignedQuery<PaymentInformation>(
    "/v1/developer/payment-information",
  );
  const updateBillingMutation = useSignedMutation(
    "/v1/developer/payment-information",
    {
      method: "PUT",
    },
    {
      onSuccess: () => setOpen(false),
    },
  );

  return (
    <Dialog open={open} onOpenChange={(newOpen) => setOpen(newOpen)}>
      <DialogTrigger asChild>
        <ActionButton
          variant="outline"
          type="button"
          isPending={updateBillingMutation.isPending}
          className="flex"
        >
          <div className="flex items-center gap-2">
            <CreditCardIcon size={16} />
            Update Billing Information
          </div>
        </ActionButton>
      </DialogTrigger>
      <DialogContent className="p-0 gap-0">
        <form
          onSubmit={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            setError(null);

            const formData = new FormData(e.currentTarget);
            const data = {
              firstName: formData.get("firstName"),
              lastName: formData.get("lastName"),
              address: formData.get("address"),
              city: formData.get("city"),
              postalCode: formData.get("postalCode"),
              state: formData.get("state"),
              country: formData.get("country"),
            };

            try {
              updateBillingMutation.mutate({
                data,
              });
            } catch (e) {
              const fieldsMessage =
                e?.fields?.length > 0
                  ? " Check the following fields: " + e?.fields?.join(", ")
                  : "";
              setError(
                (e.message ? e.message : "An unknown error occurred") +
                  fieldsMessage,
              );
            }
          }}
        >
          <DialogHeader className="p-6 pb-0">
            <DialogTitle>Billing Information</DialogTitle>
            <DialogDescription>
              Your billing information and a place to update them
            </DialogDescription>
          </DialogHeader>

          <div className="px-6 py-4">
            <div className="space-y-2">
              <Label htmlFor="cardNumber">Current Billing Information</Label>
              <Input
                disabled={true}
                defaultValue={`${paymentInformation.data.first_name} ${paymentInformation.data.last_name}`}
                name="name"
                required
              />
              <Input
                disabled={true}
                defaultValue={`${paymentInformation.data.payment_method?.card_type} ${
                  "**** ".repeat(3) +
                  paymentInformation.data.payment_method?.last_four
                }`}
                name="number"
                required
              />
            </div>
          </div>

          <div className="p-0 flex flex-col gap-2">
            {error ||
              (updateBillingMutation.isError && (
                <>
                  <HorizontalLine />
                  <div className="px-6 pb-6">
                    <Callout type="caution">
                      {error ||
                        (updateBillingMutation.error !== null &&
                          (updateBillingMutation.error as { message: string })
                            .message)}
                    </Callout>
                  </div>
                </>
              ))}
          </div>
          <CreditCardForm
            defaultValues={{
              postalCode: paymentInformation.data?.address?.postal_code,
              city: paymentInformation.data?.address?.city,
              state: paymentInformation.data?.address?.region,
              address: paymentInformation.data?.address?.street1,
              country: paymentInformation.data?.address?.country,
              firstName: paymentInformation.data?.first_name,
              lastName: paymentInformation.data?.last_name,
            }}
          />

          <DialogFooter className="p-6 flex sm:justify-between w-full gap-2">
            <DialogClose asChild>
              <Button type="submit" variant="outline" size="sm">
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" variant="secondary" size="sm">
              Update
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
