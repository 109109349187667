import { cn } from "../components/cn";
import {
  CheckIcon,
  CopyIcon,
  EyeIcon,
  EyeOffIcon,
  RotateCwIcon,
  Trash2Icon,
} from "lucide-react";
import { ReactNode, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "zudoku/ui/Button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "zudoku/ui/AlertDialog";
import { Input } from "zudoku/ui/Input";
import { ActionButton } from "zudoku/ui/ActionButton";

export type Key = {
  id?: string;
  key: string;
  createdOn: string;
  expiresOn: string;
};

type ApiKeyService = {
  rollKey?: (id: string) => Promise<void>;
  deleteKey?: (id: string) => Promise<void>;
  deleteGroup?: (id: string) => Promise<void>;
  updateKeyDescription?: (apiKey: {
    id: string;
    description: string;
  }) => Promise<void>;
  getUsage?: (apiKeys: string[]) => Promise<void>;
  createKey?: (apiKey: {
    description: string;
    expiresOn?: string;
  }) => Promise<void>;
};

export const ApiKeyGroup = ({
  id,
  description,
  title,
  keys,
  service,
}: {
  id: string;
  title: string;
  description?: string;
  keys: Key[];
  service: ApiKeyService;
}) => {
  const [confirmInput, setConfirmInput] = useState("");
  const queryClient = useQueryClient();

  const deleteKeyMutation = useMutation({
    mutationFn: (id: string) => {
      if (!service.deleteKey) {
        throw new Error("deleteKey not implemented");
      }

      return service.deleteKey(id);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["api-keys"] });
    },
  });

  const rollKeyMutation = useMutation({
    mutationFn: (id: string) => {
      if (!service.rollKey) {
        throw new Error("rollKey not implemented");
      }

      return service.rollKey(id);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["api-keys"] }),
  });

  const deleteGroup = useMutation({
    mutationFn: (id: string) => {
      if (!service.deleteGroup) {
        throw new Error("rollKey not implemented");
      }

      return service.deleteGroup(id);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["api-keys"] }),
  });

  return (
    <div>
      <div className="flex gap-2 items-center mb-1">
        <div className="font-semibold">{title}</div>
        {service.rollKey && (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <ActionButton
                isPending={deleteGroup.isPending}
                title="Delete this app?"
                variant="ghost"
              >
                <Trash2Icon size={16} />
              </ActionButton>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Are you sure you want to delete this app?
                </AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete
                  your App and Key. <br />
                  <div className="font-medium mt-2">
                    To delete this app, type{" "}
                    <span className="text-destructive">{title} </span>
                    to confirm you want to delete it.
                  </div>
                  <Input
                    placeholder={title}
                    onChange={(e) => setConfirmInput(e.target.value)}
                    value={confirmInput}
                    className="w-full mt-4"
                    type="text"
                  />
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>

                <AlertDialogAction asChild>
                  <ActionButton
                    isPending={deleteGroup.isPending}
                    disabled={title !== confirmInput}
                    onClick={() => deleteGroup.mutate(id)}
                    variant="destructive"
                  >
                    Delete App
                  </ActionButton>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </div>
      <ul className={cn("w-full flex flex-col gap-4")}>
        {[...keys]
          .sort((a, b) =>
            a.expiresOn && b.expiresOn
              ? new Date(a.expiresOn).getTime() -
                new Date(b.expiresOn).getTime()
              : a.expiresOn
                ? 1
                : -1,
          )
          .map((key, i) => (
            <li className="flex gap-0.5 flex-col" key={key.id}>
              {description}
              <div className="flex flex-row gap-2">
                <RevealApiKey apiKey={key.key}>
                  {i === 0 && (
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <ActionButton
                          isPending={rollKeyMutation.isPending}
                          variant="outline"
                          size="icon"
                          disabled={rollKeyMutation.isPending}
                          className="flex-shrink-0"
                        >
                          <RotateCwIcon size={16} />
                        </ActionButton>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Do you want to roll this key?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This will invalidate the current key and generate a
                            new. Make sure to update your applications with the
                            new key.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction asChild>
                            <button
                              onClick={() => rollKeyMutation.mutate(key.id)}
                            >
                              Continue
                            </button>
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  )}
                  {key.expiresOn &&
                    new Date(key.expiresOn) < new Date() &&
                    service.deleteKey && (
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            disabled={deleteKeyMutation.isPending}
                          >
                            <Trash2Icon size={16} />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              Do you want to delete this key?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              This key has expired and is no longer valid and
                              can't be used to authenticate requests.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction asChild>
                              <button
                                onClick={() => deleteKeyMutation.mutate(key.id)}
                              >
                                Continue
                              </button>
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    )}
                </RevealApiKey>
              </div>
              <div className="text-muted-foreground text-sm">
                {key.createdOn && (
                  <>Created on {new Date(key.createdOn).toLocaleDateString()}</>
                )}
                {key.createdOn && key.expiresOn && ", "}
                {key.expiresOn && (
                  <>Expires on {new Date(key.expiresOn).toLocaleDateString()}</>
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

const RevealApiKey = ({
  apiKey,
  children,
}: {
  apiKey: string;
  children?: ReactNode;
}) => {
  const [revealed, setRevealed] = useState(false);
  const [copied, setCopied] = useState(false);

  return (
    <div className="flex gap-2 items-center text-sm w-full">
      <div className="border rounded bg-gray-100 dark:bg-transparent p-1 font-mono truncate h-9 items-center flex px-2 w-full">
        {revealed ? apiKey : "•".repeat(apiKey.length)}
      </div>
      <Button
        variant="outline"
        onClick={() => setRevealed((prev) => !prev)}
        size="icon"
        className="flex-shrink-0"
      >
        {revealed ? <EyeOffIcon size={16} /> : <EyeIcon size={16} />}
      </Button>
      <Button
        variant="outline"
        className="flex-shrink-0"
        onClick={() => {
          void navigator.clipboard.writeText(apiKey).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          });
        }}
        size="icon"
      >
        {copied ? <CheckIcon size={16} /> : <CopyIcon size={16} />}
      </Button>
      {children}
    </div>
  );
};
