import { Head, Link, useAuth } from "zudoku/components";
import { Button } from "zudoku/ui/Button";

export const LandingPage = () => {
  const { isAuthenticated } = useAuth();

  return (
    <section className="">
      <Head>
        <title>Home</title>
      </Head>
      <div className="grid lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7 mt-10 lg:mt-0">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
            Build the{" "}
            <span className="text-primary/80">weather experience</span> like
            never before.
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            <Link to="/">Sign up for an AccuWeather APIs account</Link> and get
            free access to a sampling of our weather API endpoints, including
            Locations, Current Conditions, and Daily and Hourly Forecasts.
            Limited Trial access allows each developer up to 50 calls per day.
          </p>
          <div className="flex gap-4">
            <Button variant="outline" size="xl" asChild>
              <Link to="/documentation/getting-started">Explore API</Link>
            </Button>
            <Button size="xl" asChild>
              <Link
                to={
                  isAuthenticated ? "/documentation/getting-started" : "/signup"
                }
              >
                Get started
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </Button>
          </div>
        </div>
        <div
          className="hidden lg:mt-0 lg:col-span-5 lg:flex rounded-3xl shadow"
          style={{
            backgroundImage: "url(/AccuWeatherHeader.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      </div>
    </section>
  );
};
